// https://select2.org/programmatic-control/events

import $ from 'jquery'
import 'select2'
import cs from 'select2/src/js/select2/i18n/cs'

import { selectors } from '../selectors'
import { elementExists } from '../shared'

export const init = () => {
    initFor(selectors.inputs.bank) // RegistrationClassic
    initFor(selectors.inputs.banks) // ClientActualizeBankAccount
}

const initFor = (selector) => {
    if (!elementExists(selector)) {
        return
    }

    const $input = $(selector)

    $input.select2({
        language: cs,
        placeholder: '0000 - (Vaše banka)',
        width: 'resolve',
    })

    $input.on('select2:open select2:select', () => {
        $input.parents('label').find('.select2-selection').addClass('active')
    })
    $input.on('select2:close', () => {
        !$input._isValid() && $input.parents('label').find('.select2-selection').removeClass('active')
    })
}
