import $ from 'jquery'

import { elementExists } from '../shared'
import { selectors } from '../selectors'
import { ID_CARD_PLACEHOLDER } from './nationality'
import { event } from '../validation/ruleset'
import { input } from '../validation/enums'

export const init = () => {
    initFor(selectors.inputs.nationality, selectors.inputs.idCardNumber) // RegistrationClassic
}

const initFor = (nationalitySelector, idCardSelector) => {
    if (!elementExists(nationalitySelector) || !elementExists(idCardSelector)) {
        return
    }

    const [$inputNationality, $inputIDCard] = [$(nationalitySelector), $(idCardSelector)]

    $inputNationality.on(event[input.select], () => {
        const nationalityId = $inputNationality.val()

        $inputIDCard.prop('placeholder', ID_CARD_PLACEHOLDER[nationalityId])
    })
}
