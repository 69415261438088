import $ from 'jquery'
import moment from 'moment'

import { elementExists } from '../shared'
import { randomIntFromInterval } from '../functions'
import { translations } from '../config'
import { selectors } from '../selectors'

// TODO refactor !!
export default function () {
    if (!elementExists(selectors.content.ratings)) {
        return
    }

    let currentRating
    const RATINGS_LENGTH = 5

    // Calculate Ratings Count
    const calculateRatingsCount = () => {
        const from = moment([2009, 9])
        const to = moment()

        return Math.round(to.diff(from, 'days') / 2)
    }

    //Ratings functions
    const fillRatingToHtml = (rating) => {
        $(selectors.content.ratingsTextsName).html(translations.ratings[rating].name)
        $(selectors.content.ratingsTextsAge).html('(' + translations.ratings[rating].age + ')')
        $(selectors.content.ratingsTextsText).html(translations.ratings[rating].text)
    }

    const setRandomRating = () => {
        currentRating = randomIntFromInterval(0, RATINGS_LENGTH - 1)
        fillRatingToHtml(currentRating)
    }

    const setNextRating = () => {
        currentRating++

        if (currentRating === RATINGS_LENGTH) {
            currentRating = 0
        }

        fillRatingToHtml(currentRating)
    }

    const setPreviousRating = () => {
        currentRating--

        if (currentRating < 0) {
            currentRating = RATINGS_LENGTH - 1
        }

        fillRatingToHtml(currentRating)
    }

    // Sets things Up
    $(selectors.content.ratingsCount).html(calculateRatingsCount())
    setRandomRating()

    // Events
    $(selectors.buttons.ratingsSliderPrev).click(() => setPreviousRating())
    $(selectors.buttons.ratingsSliderNext).click(() => setNextRating())
}
