import $ from 'jquery'
import _ from 'lodash'

import { $elementHasVal, elementExists } from '../shared'
import { selectors } from '../selectors'
import { event } from '../validation/ruleset'
import { input } from '../validation/enums'

export const MONEY_SOURCE = {
    FULL_TIME: 'full_time',
    PART_TIME: 'part_time',
    ENTREPRENEUR: 'entrepreneur', // OSVČ
    FULL_PARENTAL: 'full_parental',
    PENSION: 'pension',
    OTHER: 'other',
    BRIGADE: 'brigade',
}

export const MONEY_SOURCE_ID = {
    [MONEY_SOURCE.FULL_TIME]: '1',
    [MONEY_SOURCE.PART_TIME]: '2',
    [MONEY_SOURCE.ENTREPRENEUR]: '3',
    [MONEY_SOURCE.FULL_PARENTAL]: '4',
    [MONEY_SOURCE.PENSION]: '5',
    [MONEY_SOURCE.OTHER]: '6',
    [MONEY_SOURCE.BRIGADE]: '7',
}

const FORM_DATA_ATTR_SELECTOR = '[data-reveal-money-source]'
const FORM_DATA_ATTR = 'data-reveal-money-source'

export const init = () => {
    hideAll() // Just in case, elements should have [style="display: none;"] attr

    initFor(selectors.inputs.moneySource) // RegistrationClassic
    initFor(selectors.inputs.moneySourceComplete) // RegistrationComplete
    initFor(selectors.inputs.moneySourceClientComplete) // ClientData + ClientComplete
    initFor(selectors.inputs.moneySourceClientActualize) // Client Actualize
}

// ------------------------------------------------------

const initFor = (selector) => {
    if (!elementExists(selector)) {
        return
    }

    const $choicesMoneySource = $(selector)

    $choicesMoneySource.on(event[input.radio], () => {
        const selectedId = $(selector + ':checked').val()
        revealFormSections(selectedId)
    })

    $elementHasVal($choicesMoneySource) && $(selector + ':checked').trigger(event[input.radio])
}

const hideAll = () => $(FORM_DATA_ATTR_SELECTOR).hide()

const revealFormSections = (moneySourceId) => {
    const moneySourceIdent = _.invert(MONEY_SOURCE_ID)[moneySourceId]

    $(FORM_DATA_ATTR_SELECTOR).each((_, element) => {
        $(element).attr(FORM_DATA_ATTR).includes(moneySourceIdent) ? $(element).show() : $(element).hide() // slide[Down|Up] -> [show|hide] : CC-2837
    })
}
