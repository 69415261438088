import $ from 'jquery'
import { constructIframe, elementExists } from './shared'
import { selectors } from './selectors'

export default function () {
    // SectionID
    if (!elementExists('#howitworks')) {
        return
    }

    // Play Vid on frame or button click
    $(selectors.content.videoFrame).click(function () {
        const $fakePlayButton = $(this).find(selectors.buttons.fakePlayButton)
        $fakePlayButton.click()
    })

    $(selectors.content.videoFrame).on('click', selectors.buttons.fakePlayButton, function () {
        const videoID = $(this).attr('data-id')
        const $iframe = constructIframe(videoID)
        const $videoFrame = $(this).parents(selectors.content.videoFrame)

        $videoFrame.find('.fake-iframe').detach()
        $videoFrame.append($iframe)
    })
}
