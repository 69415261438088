import $ from 'jquery'
import 'rangeslider.js'

import { initSlider, formatDate, formatNumberAndCurrency, formatNumberAndPercents } from './helpers'
import { calculateFeeMorePayments, calculateInstallment, calculateRpsnMorePayments } from '../functions'
import { getPromoSaleAttr, EVENT_PROMOSALE_CHANGE } from '../form/promoCode'
import { setHomepageSliderCookies } from '../cookies'
import { elementExists } from '../shared'
import { selectors } from '../selectors'

// https://rangeslider.js.org/

// Wrappers
const amountWrappers = [
    { selector: selectors.sliders.amount1WrapperMorePayments, index: 1 },
    { selector: selectors.sliders.amount3WrapperMorePayments, index: 3 },
]

// Tables
const tables = [{ selector: selectors.sliders.calculator }, { selector: selectors.sliders.calculatorRegistration }]

export const initSliders = () => {
    for (const wrapper of amountWrappers) {
        elementExists(wrapper.selector) && initSlider($(wrapper.selector), updateHandleAmount)
    }

    initSliderListeners()
}

const rewriteTable = (amount) => {
    amount = parseInt(amount)

    const totalInstallments = 3
    const days = process.env.SLIDER_LOAN_MORE_DAYS
    const daysPerInstallment = process.env.SLIDER_DAYS_PER_INSTALLMENT
    const fee = calculateFeeMorePayments(amount, days)
    const promoSale = (getPromoSaleAttr() / 100) * fee
    const installment = calculateInstallment(amount, fee - promoSale, totalInstallments)

    for (const tab of tables) {
        const table = tab.selector
        if (!elementExists(table)) {
            continue
        }

        // amount, date, rpsn, fee, promoSale, total rewrites
        $(table + ' span.j-amount').html(formatNumberAndCurrency(amount))
        $(table + ' span.j-date').html(formatDate(days))
        $(table + ' span.j-fee').html(formatNumberAndCurrency(fee - promoSale))
        $(table + ' span.j-promosale').html(formatNumberAndCurrency(promoSale))
        $(table + ' span.j-total').html(formatNumberAndCurrency(amount + fee - promoSale))
        $(table + ' span.j-instalment').html(formatNumberAndCurrency(installment))
        $(table + ' span.j-rpsn').html(formatNumberAndPercents(calculateRpsnMorePayments(amount, fee - promoSale, totalInstallments, daysPerInstallment)))
    }
}

const updateHandleAmount = ($handle, amountVal) => {
    $handle.html(formatNumberAndCurrency(amountVal)) // Set updated handle text

    rewriteTable(amountVal)
    setHomepageSliderCookies(amountVal, process.env.SLIDER_LOAN_MORE_DAYS)
}

const initSliderListeners = () => {
    $(document).on(EVENT_PROMOSALE_CHANGE, () => {
        amountWrappers.map((wrapper) => {
            $(wrapper.selector).each((_, el) => $(el).find('input.rangeslider').trigger('input'))
        })
    })
}
