'use strict'

import $ from 'jquery'
import _ from 'lodash'

import { ATTR, construct$Validator, construct$Submit, hasClientRule, hasCustomRule, hasExternalRule, hasMask, hasRegex, hasSelected, init$fnExtensions, pair$Elements, hasRanged, isHTMLValid } from './helpers'
import { ruleset, type, event, errorEvent, fn } from './ruleset'
import { input } from './enums'

export default function () {
    init$fnExtensions()

    _.forIn(ruleset, ({ callbacks, inputs }, selector) => {
        const submit = { selector, callbacks }

        resolveSubmitEvents(submit)

        const $submit = construct$Submit(submit)

        _.forIn(inputs, (rules, selector) => {
            const input = { selector, rules }

            initInputMasks(input)
            initInputDataAttributes(input)

            resolveInputTypes(input)
            resolveInputEvents(input)
            resolveInputValidators(input)

            const $input = construct$Validator(input)

            pair$Elements($submit, $input)
        })
    })
}

// ------------ Initiators ------------

const initInputMasks = ({ selector, rules }) => {
    hasMask(rules) && rules.mask.mask(selector)
}

const initInputDataAttributes = ({ selector, rules }) => {
    hasClientRule(rules) && $(selector).attr(ATTR.VALID._CLIENT, ATTR.STATE._INIT)
    hasCustomRule(rules) && $(selector).attr(ATTR.VALID._CUSTOM, ATTR.STATE._INIT)
    hasExternalRule(rules) && $(selector).attr(ATTR.VALID._EXTERNAL, ATTR.STATE._INIT)
}

// ------------ Resolvers ------------

const resolveInputTypes = (input) => {
    const { selector } = input

    _.extend(input, {
        type: _.findKey(type, (typeTest) => typeTest(selector)),
    })
}

const resolveSubmitEvents = (submit) => {
    _.extend(submit, {
        events: event[input.submit],
    })
}

const resolveInputEvents = (input) => {
    const { type } = input

    _.extend(input, {
        events: event[type],
        errorEvents: errorEvent[type],
    })
}

const resolveInputValidators = (input) => {
    _.extend(input, {
        processClient: resolveClientValidation(input),
        processCustom: resolveCustomValidation(input),
        processExternal: resolveExternalValidation(input),
    })
}

const resolveClientValidation = (input) => {
    const { selector, rules, type } = input

    return () => {
        return new Promise((resolve, reject) => {
            if (hasRegex(rules)) {
                fn[type](input) ? resolve(true) : reject()
            }
            if (hasMask(rules)) {
                const mask = _.first($(selector)).inputmask
                mask.isComplete() && mask.isValid() ? resolve(true) : reject()
            }
            if (hasSelected(rules) || hasRanged(rules)) {
                fn[type](input) ? resolve(true) : reject()
            }
            if (!isHTMLValid(selector)) {
                reject()
            }

            resolve(null)
        })
    }
}

const resolveCustomValidation = (input) => {
    const { rules } = input

    return () => {
        return new Promise((resolve, reject) => {
            hasCustomRule(rules) ? rules.custom(input, { resolve, reject }) : resolve(null)
        })
    }
}

const resolveExternalValidation = (input) => {
    const { rules } = input

    return () => {
        return new Promise((resolve, reject) => {
            hasExternalRule(rules) ? rules.external(input, { resolve, reject }) : resolve(null)
        })
    }
}
