import $ from 'jquery'
import { selectors } from '../selectors'

export default function () {
    $(window).scroll(() => {
        $(document).scrollTop() > 0 && $(selectors.content.header).addClass('small')
        $(document).scrollTop() <= 0 && $(selectors.content.header).removeClass('small')
    })

    // Remove the background property, so it comes transparent again (defined in _header.scss)
    $(window).scroll(() => {
        $(window).scrollTop() > 20 && $('header').addClass('active')
        $(window).scrollTop() <= 20 && $('header').removeClass('active')
    })
}
