import Cookies from 'js-cookie'

// Be aware of CookieHelper:: -> configuration must be the same, or cookies will be unreadable from .js

const DEFAULT_OPTIONS = { sameSite: 'lax' }

const HOMEPAGE_DEFAULT_EXPIRATION = 7

const HOMEPAGE_AMOUNT = 'homepage_form_amount'
const HOMEPAGE_DAYS = 'homepage_form_days'
const HOMEPAGE_LOAN_TYPE = 'homepage_form_loan_type'
const HOMEPAGE_PROMO_CODE = 'homepage_form_promo_code'

// -------------- Get/Set --------------

export const getCookie = (key) => Cookies.get(key)

export const setCookie = (key, value, options = {}) => Cookies.set(key, value, { ...DEFAULT_OPTIONS, ...options })

// -------------- Homepage --------------

export const getHomepageCookies = () => [Cookies.get(HOMEPAGE_AMOUNT), Cookies.get(HOMEPAGE_DAYS), Cookies.get(HOMEPAGE_LOAN_TYPE), Cookies.get(HOMEPAGE_PROMO_CODE)]

export const getHomepageLoanTypeCookie = () => Cookies.get(HOMEPAGE_LOAN_TYPE)

export const setHomepageSliderCookies = (amount, days) => {
    setCookie(HOMEPAGE_AMOUNT, amount, { expires: HOMEPAGE_DEFAULT_EXPIRATION })
    setCookie(HOMEPAGE_DAYS, days, { expires: HOMEPAGE_DEFAULT_EXPIRATION })
}

export const setHomepageLoanTypeCookies = (loanType) => setCookie(HOMEPAGE_LOAN_TYPE, loanType, { expires: HOMEPAGE_DEFAULT_EXPIRATION })

export const setHomepagePromoCodeCookies = (promoCode) => setCookie(HOMEPAGE_PROMO_CODE, promoCode, { expires: HOMEPAGE_DEFAULT_EXPIRATION })
