import $ from 'jquery'

const TICK = 10 // ms
let DEGREE = 0

export default function () {
    window.rotate = rotate
}

const rotate = () => {
    DEGREE++

    setTimeout(() => {
        $('body').css({ transform: 'rotate(' + DEGREE + 'deg)', zoom: 1 })
        rotate()
    }, TICK)
}
