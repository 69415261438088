import $ from 'jquery'

export default function () {
    const PAGE_HEADER_HEIGHT = 70 // px
    const ANIMATION_SPEED = 1000 // ms

    $("a[href^='#']").on('click', function () {
        const href = $(this).attr('href')

        // Sanitize href="#"
        if (href.length === 1) {
            return
        }

        $('html, body').animate({ scrollTop: $(href).offset().top - PAGE_HEADER_HEIGHT }, ANIMATION_SPEED)

        return false
    })

    // TODO Should be on page load probably ??
    /* if ($(window.location.hash).length > 1) {
        $('html, body').animate(
            {
                scrollTop: $(window.location.hash).offset().top - PAGE_HEADER_HEIGHT,
            },
            ANIMATION_SPEED
        )
        return false
    } */
}
