import $ from 'jquery'

import { $elementHasVal, elementExists } from '../shared'
import { selectors } from '../selectors'
import { event } from '../validation/ruleset'
import { input } from '../validation/enums'

const TYPE = {
    MALE: 'male',
    FEMALE: 'female',
}

const DATA_ATTR_TRANS = {
    [TYPE.MALE]: 'data-trans-male',
    [TYPE.FEMALE]: 'data-trans-female',
}

export const ID_NUMBER_PREFIX = {
    [TYPE.MALE]: '^..[^56].*$',
    [TYPE.FEMALE]: '^..[56].*$',
}

export const init = () => {
    initFor(selectors.inputs.maritalStatus, selectors.inputs.nin) // RegistrationClassic
    initFor(selectors.inputs.maritalStatusClientComplete, selectors.inputs.ninClientComplete) // ClientComplete
}

const initFor = (maritalStatusSelector, idNumberSelector) => {
    if (!elementExists(maritalStatusSelector) || !elementExists(idNumberSelector)) {
        return
    }

    const [$choicesMaritalStatus, $inputIdNumber] = [$(maritalStatusSelector), $(idNumberSelector)]

    $inputIdNumber.on(event[input.text], () => {
        const idNumber = $inputIdNumber.val()

        idNumber.match(ID_NUMBER_PREFIX[TYPE.MALE]) && setTransMutation($choicesMaritalStatus, TYPE.MALE)
        idNumber.match(ID_NUMBER_PREFIX[TYPE.FEMALE]) && setTransMutation($choicesMaritalStatus, TYPE.FEMALE)
    })

    $elementHasVal($inputIdNumber) && $inputIdNumber.trigger('change')
}

const setTransMutation = ($choices, type) => {
    $choices.each((_, el) => {
        const $choiceLabel = $(el).parents('label').find('span')
        const choiceMutation = $choiceLabel.attr(DATA_ATTR_TRANS[type])

        $choiceLabel.text(choiceMutation)
    })
}
