import { config } from './config' // TODO

export const formatNumber = (number) => {
    let num = Math.round(number)
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\xa0')
}

export const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const feeFormula = config.feeFormula
const feeFormulaMorePayments = config.feeFormulaMorePayments

export const calculateFee = (amount, days) => {
    amount = parseInt(amount)
    const formula = feeFormula.replace(/X/g, amount).replace(/Y/g, days)

    let fee
    eval('fee = ' + formula)
    return Math.round(fee)
}

export const calculateFeeMorePayments = (amount, days) => {
    amount = parseInt(amount)
    const formula = feeFormulaMorePayments.replace(/X/g, amount).replace(/Y/g, days)

    let fee
    eval('fee = ' + formula)
    return Math.round(fee)
}

export const calculateInstallment = (amount, fee, installments) => {
    amount = parseInt(amount)
    fee = parseInt(fee)
    installments = parseInt(installments)

    return Math.round((amount + fee) / installments)
}

const calculatorConfig = {
    iterations: 10,
    year: 365,
    minRpsn: -0.00001 / 100,
    maxRpsn: 100000000 / 100,
}

// ---- RPSN OnePayment

const calculateRPSNLeftSide = (bound, amount) => {
    const exponent = 0 / calculatorConfig.year

    const base = bound + 1
    const denominator = base ** exponent

    return amount / denominator
}

const calculateRPSNRightSide = (bound, amountFee, days) => {
    const exponent = days / calculatorConfig.year

    const base = bound + 1
    const denominator = base ** exponent

    return amountFee / denominator
}

export const calculateRpsn = (amount, fee, days) => {
    const amountFee = amount + fee

    let jump = calculatorConfig.iterations
    let leftBound = calculatorConfig.minRpsn
    let rightBound = calculatorConfig.maxRpsn
    let closestDiff = calculatorConfig.maxRpsn

    let newLeftBound = 0
    let newRightBound = 0

    let result = 0
    for (let iterations = 0; iterations < calculatorConfig.iterations; iterations++) {
        let minFound = false

        for (let i = leftBound; i <= rightBound; i += jump) {
            // Calculate left side of the equation
            let leftSide = calculateRPSNLeftSide(i, amount)

            // Calculate right side of the equation
            let rightSide = calculateRPSNRightSide(i, amountFee, days)

            // Equation diff
            const diff = Math.abs(leftSide - rightSide)

            if (diff < closestDiff) {
                closestDiff = diff

                // Set new bounds, 1 jump left/right from value we found
                newLeftBound = i - jump
                newRightBound = i + jump

                result = i
                minFound = true
            }

            // Global MIN (for given interval and its steps) found, no need to continue
            if (minFound && diff > closestDiff) {
                break
            }
        }

        // Reset diff, set new bounds
        closestDiff = calculatorConfig.maxRpsn
        leftBound = newLeftBound
        rightBound = newRightBound
        jump /= calculatorConfig.iterations
    }

    // Convert to percents
    return result * 100
}

// ---- RPSN MorePayments

const prepareDataMP = (amountFee, totalInstallmens, daysPerInstallment) => {
    let installments = []

    const amountFeePerInstallment = Math.round(amountFee / totalInstallmens)

    for (let i = 1; i <= totalInstallmens; i++) {
        const exponent = (i * daysPerInstallment) / calculatorConfig.year

        installments.push({
            amountFee: amountFeePerInstallment,
            days: daysPerInstallment,
            exponent: exponent,
        })
    }

    return installments
}

const calculateRPSNLeftSideMP = (bound, amount) => {
    const exponent = 0 / calculatorConfig.year

    const base = bound + 1
    const denominator = base ** exponent

    return amount / denominator
}

const calculateRPSNRightSideMP = (bound, installments) => {
    const base = bound + 1

    let rightSide = 0.0
    for (const installment of installments) {
        const denominator = base ** installment.exponent
        const result = installment.amountFee / denominator

        rightSide += result
    }

    return rightSide
}

export const calculateRpsnMorePayments = (amount, fee, totalInstallmens, daysPerInstallment) => {
    const amountFee = amount + fee

    // Prepare data
    const installments = prepareDataMP(amountFee, totalInstallmens, daysPerInstallment)

    let jump = calculatorConfig.iterations
    let leftBound = calculatorConfig.minRpsn
    let rightBound = calculatorConfig.maxRpsn
    let closestDiff = calculatorConfig.maxRpsn

    let newLeftBound = 0
    let newRightBound = 0

    let result = 0
    for (let iterations = 0; iterations < calculatorConfig.iterations; iterations++) {
        let minFound = false

        for (let i = leftBound; i <= rightBound; i += jump) {
            // Calculate left side of the equation
            let leftSide = calculateRPSNLeftSideMP(i, amount)

            // Calculate right side of the equation
            let rightSide = calculateRPSNRightSideMP(i, installments)

            // Equation diff
            const diff = Math.abs(leftSide - rightSide)

            if (diff < closestDiff) {
                closestDiff = diff

                // Set new bounds, 1 jump left/right from value we found
                newLeftBound = i - jump
                newRightBound = i + jump

                result = i
                minFound = true
            }

            // Global MIN (for given interval and its steps) found, no need to continue
            if (minFound && diff > closestDiff) {
                break
            }
        }

        // Reset diff, set new bounds
        closestDiff = calculatorConfig.maxRpsn
        leftBound = newLeftBound
        rightBound = newRightBound
        jump /= calculatorConfig.iterations
    }

    // Convert to percents
    return result * 100
}
