import $ from 'jquery'
import '@popperjs/core'
import { selectors } from '../selectors'

// Be aware of [title="" tabindex="-1"]

export default function () {
    $(selectors.popovers.all).each((_, el) => {
        $(el).popover({
            container: 'body',
        })
    })

    $(selectors.popovers.marketingInfo).on('click', (e) => {
        e.preventDefault()
    })
}
