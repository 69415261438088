import $ from 'jquery'
import { $elementExists } from '../shared'
import { selectors } from '../selectors'

const INPUT_TYPES = {
    TEXT: 'text',
    PASSWORD: 'password',
}

const PASSWORD_INPUTS = [selectors.inputs.changePasswordFirst, selectors.inputs.changePasswordSecond, selectors.inputs.loginPassword]

const togglePassword = ($passwordInput) => {
    const $inputWrapper = $passwordInput.closest(selectors.inputs.passwordInputWrapper)
    const $toggleButton = $inputWrapper.find(selectors.buttons.togglePasswordVisibility)

    if (!($elementExists($passwordInput) && $elementExists($toggleButton))) return

    $toggleButton.on('click', () => {
        const isTextType = $passwordInput.attr('type') === INPUT_TYPES.TEXT
        $passwordInput.attr('type', isTextType ? INPUT_TYPES.PASSWORD : INPUT_TYPES.TEXT)
        $toggleButton.toggleClass('fa-eye-slash', !isTextType).toggleClass('fa-eye', isTextType)
    })
}

export const init = () => {
    PASSWORD_INPUTS.forEach((inputSelector) => {
        const $passwordInput = $(inputSelector)
        togglePassword($passwordInput)
    })
}
