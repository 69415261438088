import $ from 'jquery'
import 'select2'
import mask from '../validation/masks'

import { elementExists } from '../shared'
import { selectors } from '../selectors'
import { NATIONALITY_ID, COUNTRY_CODE, PHONE_NUMBER_PREFIX } from './nationality'

const SELECT_PREFIX_INPUTS = [selectors.inputs.phoneNumberSelectHomepage, selectors.inputs.phoneNumberSelectRegistration, selectors.inputs.phoneNumberSelectBlog, selectors.inputs.phoneNumberSelectSms]

export default function initializePhoneNumberInputs() {
    SELECT_PREFIX_INPUTS.forEach((select) => {
        initSelect2(select)
        initMask(select)
    })
}

function initSelect2(select) {
    $(select).select2({
        width: 'resolve',
        minimumResultsForSearch: -1, // Hides default <input search in dropdown. Don't need 'em if only 3 results to pick.
    })
}

function initMask(select) {
    if (!elementExists(select)) {
        return
    }

    const czechNumberRegex = new RegExp(PHONE_NUMBER_PREFIX[NATIONALITY_ID.CZECH])
    const slovakNumberRegex = new RegExp(PHONE_NUMBER_PREFIX[NATIONALITY_ID.SLOVAK])

    const phoneNumberSelect = $(select)
    const phoneNumberInput = phoneNumberSelect.closest(selectors.inputs.prefixSelectWrapper).find(selectors.inputs.inputTelType)

    if (czechNumberRegex.test($(phoneNumberInput).val())) {
        phoneNumberSelect.val(COUNTRY_CODE[NATIONALITY_ID.CZECH]).change()
    }
    if (slovakNumberRegex.test($(phoneNumberInput).val())) {
        phoneNumberSelect.val(COUNTRY_CODE[NATIONALITY_ID.SLOVAK]).change()
    }

    const updateMask = (selectedCountryCode) => {
        if (selectedCountryCode === COUNTRY_CODE[NATIONALITY_ID.CZECH]) {
            mask.phoneNumberSlovak.remove(phoneNumberInput)
            mask.phoneNumberCzech.mask(phoneNumberInput)
        } else if (selectedCountryCode === COUNTRY_CODE[NATIONALITY_ID.SLOVAK]) {
            mask.phoneNumberCzech.remove(phoneNumberInput)
            mask.phoneNumberSlovak.mask(phoneNumberInput)
        }
    }

    phoneNumberSelect.on('change', () => {
        phoneNumberInput.val('')
        updateMask(phoneNumberSelect.val())
    })

    updateMask(phoneNumberSelect.val())
}
