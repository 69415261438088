import $ from 'jquery'
import initPopOvers from './popover'
import initYoutube from '../youtube'
import initBootstrap from './bootstrap'
import initJQueryUI from './jQueryUI'
import initRatings from './ratings'
import initValidations from '../validation/validator'
import initResizeHeader from './resizeHeader'
import initPhoneNumberPrefixes from '../form/phoneNumber'
import initSliderTableToggle from './showTable'
import initSideMenuToggle from './showMenu'
import initAnchorScrollTo from './scrollTo'
import { initMenu } from './menu'
import { initFrontendTimer } from '../clock'
import { initSliders as initSlidersOP } from '../slider/rangeslider'
import { initSliders as initSlidersMP } from '../slider/rangesliderMorePayments'
import { init as initTogglePasswordVisibility } from '../form/password'
import initFormElements from '../form/init'
import initEasterEgg from './easterEgg'
import { initMissingMethods } from './missingBrowserMethods'

initSlidersOP() || initSlidersMP() // Needs to be loaded asap

$(() => {
    // --- Bootstrap Components ---
    initBootstrap() // Bootstrap [+REDESIGN+]
    initJQueryUI() // jQuery-UI

    // --- Web Components ---
    initMenu() // Init menu
    initResizeHeader() // Header [+REDESIGN+]
    initAnchorScrollTo() // #href clicking [+REDESIGN+]
    initSideMenuToggle() // Init menu [+REDESIGN+]
    initSliderTableToggle() // Show/Hide Slider table on Mobile [+REDESIGN+]

    // --- Form ---
    initValidations() // Validations
    initFormElements() // Form Elms
    initPopOvers() // PopOvers [+REDESIGN+]
    initPhoneNumberPrefixes() // +42[0|1|]
    initTogglePasswordVisibility()

    // --- Other ---
    initYoutube() // Youtube Iframes
    initRatings() // Ratings
    initFrontendTimer() // Blog clocks (and other ?)
    initMissingMethods()

    // --- EasterEgg ---
    initEasterEgg()
})
