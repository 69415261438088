// https://www.smartform.cz/dokumentace/smartform-pro-web/dokumentace-api-v2/#AddressControl

import $ from 'jquery'
import _ from 'lodash'

import { $elementHasVal, elementExists } from '../shared'
import { selectors } from '../selectors'
import { event } from '../validation/ruleset'
import { input } from '../validation/enums'

const SMART_FORM_SCRIPT_URI = 'https://client.smartform.cz/v2/smartform.js'
const SMART_FORM_CLI_ID = 'XHsYPZiq0S'
const SMART_FORM_ADDRESS_FORM = 'smartform-instance-addr'
const SMART_FORM_DELIVERY_FORM = 'smartform-instance-deli'
const SMART_FORM_VALIDATE_ADDRESS_EVENT = 'validateSmartForm'

export const init = () => {
    initSmartForms() // RegistrationClassic

    initDeliveryOption(selectors.inputs.hasDeliveryAddress, selectors.forms.addressDelivery) // RegistrationClassic
    initDeliveryOption(selectors.inputs.hasDeliveryAddressClient, selectors.forms.addressDelivery) // ClientData
}

const initSmartForms = () => {
    if (!elementExists(selectors.forms.registrationForm)) {
        return
    }

    const setSelection = (element, text) => {
        new CustomEvent(SMART_FORM_VALIDATE_ADDRESS_EVENT)
        $(element).val(text).trigger(SMART_FORM_VALIDATE_ADDRESS_EVENT)
        return $(element).trigger('change')
    }

    window.smartform = {
        beforeInit: () => window.smartform.setClientId(SMART_FORM_CLI_ID),
        afterInit: () => {
            _.map([window.smartform.getInstance(SMART_FORM_ADDRESS_FORM), window.smartform.getInstance(SMART_FORM_DELIVERY_FORM)], (ins) => ins.addressControl.setSelectionCallback((element, text) => setSelection(element, text)))
        },
    }

    $.getScript(SMART_FORM_SCRIPT_URI)
}

const initDeliveryOption = (deliveryToggle, deliveryBlock) => {
    if (!elementExists(deliveryToggle) || !elementExists(deliveryBlock)) {
        return
    }

    const $deliveryToggle = $(selectors.inputs.hasDeliveryAddress)
    const $deliveryBlock = $(selectors.forms.addressDelivery)

    $deliveryToggle.on(event[input.select], () => $deliveryBlock.toggle())

    $elementHasVal($deliveryToggle) && $deliveryBlock.toggle()
}
