import $ from 'jquery'
import 'rangeslider.js'

import { initSlider, updateToolTip, formatDate, formatNumberAndCurrency, formatNumberAndPercents, formatNumberAndDays } from './helpers'
import { calculateFee, calculateRpsn } from '../functions'
import { setHomepageSliderCookies } from '../cookies'
import { getPromoSaleAttr, EVENT_PROMOSALE_CHANGE } from '../form/promoCode'
import { elementExists } from '../shared'
import { selectors } from '../selectors'

// https://rangeslider.js.org/

// Wrappers
const amountWrappers = [
    { selector: selectors.sliders.amount2Wrapper, index: 2 },
    { selector: selectors.sliders.amount3Wrapper, index: 3 },
]
const dateWrappers = [
    { selector: selectors.sliders.date2Wrapper, index: 2 },
    { selector: selectors.sliders.date3Wrapper, index: 3 },
]

// Tables
const tables = [{ selector: selectors.sliders.calculator }, { selector: selectors.sliders.calculatorRegistration }]

export const initSliders = () => {
    for (const wrapper of amountWrappers) {
        elementExists(wrapper.selector) && initSlider($(wrapper.selector), updateHandleAmount, wrapper.index)
    }

    for (const wrapper of dateWrappers) {
        elementExists(wrapper.selector) && initSlider($(wrapper.selector), updateHandleDate, wrapper.index)
    }

    initSliderListeners()
}

const rewriteTable = (amount, days) => {
    amount = parseInt(amount)
    days = parseInt(days)

    const fee = calculateFee(amount, days)
    const promoSale = (getPromoSaleAttr() / 100) * fee

    for (const tab of tables) {
        const table = tab.selector
        if (!elementExists(table)) {
            continue
        }

        // amount, date rewrites
        $(table + ' span.j-amount').html(formatNumberAndCurrency(amount))
        $(table + ' span.j-date').html(formatDate(days))
        $(table + ' span.j-days').html(days)
        $(table + ' span.j-promosale').html(formatNumberAndCurrency(promoSale))
        $(table + ' span.j-fee').html(formatNumberAndCurrency(fee - promoSale))
        $(table + ' span.j-total').html(formatNumberAndCurrency(amount + fee - promoSale))
        $(table + ' span.j-rpsn').html(formatNumberAndPercents(calculateRpsn(amount, fee - promoSale, days)))
    }
}

const updateHandleAmount = ($handle, amountVal, context) => {
    $handle.html(formatNumberAndCurrency(amountVal)) // Set updated handle text
    const handlePosition = $handle.position()

    // Update tooltip if there's one
    const tooltip = '.j-amountslider' + context + ' .tooltip'
    if (elementExists(tooltip)) {
        const $tooltip = $(tooltip)
        $tooltip.css({ left: handlePosition.left })

        updateToolTip($tooltip, amountVal) // Show/Hide tooltip if necessary
    }

    const daysSlider = '.j-dateslider' + context + ' input.rangeslider' // Get brother value and update table if there's one
    const daysVal = elementExists(daysSlider) ? $(daysSlider).val() : process.env.SLIDER_INIT_DAYS

    rewriteTable(amountVal, daysVal)
    setHomepageSliderCookies(amountVal, daysVal)
}

const updateHandleDate = ($handle, daysVal, context) => {
    $handle.html(formatNumberAndDays(daysVal)) // Set updated handle text

    // Get brother value and update table if there's one
    const amountSlider = '.j-amountslider' + context + ' input.rangeslider'
    const amountVal = $(amountSlider).val()

    rewriteTable(amountVal, daysVal)
    setHomepageSliderCookies(amountVal, daysVal)
}

const initSliderListeners = () => {
    $(document).on(EVENT_PROMOSALE_CHANGE, () => {
        amountWrappers.map((wrapper) => {
            $(wrapper.selector).each((_, el) => $(el).find('input.rangeslider').trigger('input'))
        })
    })
}
