import $ from 'jquery'

import { selectors } from '../selectors'
import { $elementExists } from '../shared'

export default function () {
    const $hamburgers = $(selectors.buttons.burger)

    if (!$elementExists($hamburgers)) {
        return
    }

    $hamburgers.each((_, ham) => {
        $(ham).on('click', (_, el) => {
            $(el).toggleClass('is-active')
            $(selectors.content.sideMenu).toggleClass('sidemenu--show')
        })
    })
}
