// import $ from 'jquery'

// import { initWidget } from '../datePicker'
// import { elementExists } from '../shared'
import { selectors } from '../selectors'

export const init = () => {
    initFor(selectors.inputs.employerContractEnd) // RegistrationClassic
    initFor(selectors.inputs.employerContractEndClientComplete) // ClientData + ClientComplete
}

// ------------------------------------------------------

const initFor = () => {
    /* if (!elementExists(selector)) {
        return
    }

    const $input = $(selector)

    initWidget($input) */
}
