import $ from 'jquery'

export default function () {
    $.datepicker.setDefaults({
        closeText: 'Zavřít',
        prevText: 'Předchozí',
        nextText: 'Následující',
        currentText: 'Současný',
        monthNames: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
        monthNamesShort: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čer', 'Čer', 'Srp', 'Zář', 'Říj', 'Lis', 'Pros'],
        dayNames: ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle'],
        dayNamesShort: ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'],
        dayNamesMin: ['P', 'Ú', 'S', 'Č', 'P', 'S', 'N'],
        weekHeader: '',
        dateFormat: 'yy-mm-dd',
        altFormat: 'dd.mm.yyyy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: true,
        yearSuffix: '',
    })
}
