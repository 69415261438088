import $ from 'jquery'
import moment from 'moment'

import { formatNumber } from '../functions'
import { translations } from '../config'

export const formatNumberAndCurrency = (number) => {
    return formatNumber(number) + ' ' + translations.currencyLang
}

export const formatNumberAndDays = (number) => {
    return number + ' ' + translations.daysLang
}

export const formatNumberAndPercents = (number) => {
    return formatNumber(number) + ' %'
}

export const formatDate = (add = null, substract = null) => {
    if (add !== null) {
        return moment().add(add, 'days').format('D. M. YYYY')
    }
    if (substract !== null) {
        return moment().subtract(substract, 'days').format('D. M. YYYY')
    }
}

export const updateToolTip = ($tooltip, amountVal) => {
    let $wrapper = $tooltip.parents('.tooltip-wrap')

    if (+amountVal > +process.env.SLIDER_FIRST_LOAN_DROP) {
        $tooltip.html(formatNumberAndCurrency(amountVal) + ' <span>' + translations.loanLang1 + '</span>')
        $wrapper.show()
    } else {
        $wrapper.hide()
    }
}

export const initSlider = ($wrapper, updateHandle, context) => {
    const $slider = $wrapper.find('input.rangeslider')
    const $increment = $wrapper.find('div.range-increment')
    const $decrement = $wrapper.find('div.range-decrement')

    $slider
        .rangeslider({
            polyfill: false,
            onInit: () => {
                const $handle = $('.rangeslider__handle', $wrapper)
                updateHandle($handle, $slider.val(), context)
            },
        })
        .on('input', () => {
            const $handle = $('.rangeslider__handle', $wrapper)
            updateHandle($handle, $slider.val(), context)
        })

    $increment.on('click', shiftRangeslider.bind(null, 'stepUp'))
    $decrement.on('click', shiftRangeslider.bind(null, 'stepDown'))

    function shiftRangeslider(stepMethod) {
        $slider[0][stepMethod](1)
        $slider.trigger('change')
    }
}
